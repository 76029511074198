@media only screen and (max-width: 700px) {

    .sideBarSupportPanel {
        position: absolute;
        transform: translateX(-100%);
        transition: all .2s;
        z-index: 1000;
    }

    .sideBarSupportPanel.enabled {
        
        transform: translateX(0) !important;
    }
}

.fileUploadDelete:hover {
    opacity: 1;
}



.fileUploadDelete {
    font-weight: bold;
    color: white;
    font-size: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    transition: .3s all;
    background-color: rgba(0, 0, 0, 0.349);
}



.fileUploaderMain {
    width: 40px;
    height: 100%;
    background-color: #E7E8EA;
    position: relative;
    margin-left: 12px;
    border-radius: 8px;
    /* pointer-events: none; */
}
.fileUploaderMain label {
    width: 100%;
    height: 100%;
    display: block;
}

.fileUploaderMain label input {
    width: 100%;
    height: 100%;
    display: block;
}


