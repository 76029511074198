/* import `@fontsource/roboto/300.css`;
import `@fontsource/roboto/400.css`;
import `@fontsource/roboto/500.css`;
import "@fontsource/roboto/700.css; */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.assignedRow {
  background-color: rgba(237, 200, 100, 0.209);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


[contenteditable] ~ grammarly-extension,
input ~ grammarly-extension,
textarea ~ grammarly-extension {
  display: none;
}


textarea.inputTextArea {
  
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  border-radius: 8px;
  min-height: 20px;
}


textarea {
  
  font-size: 16px;
  font-family: "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  border-radius: 8px;
}


.deleteMessageButton {
  position: absolute;
}



.warningsDiv {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
}

.warningsDiv p {
  background-color: #e6eaee;
  width: 92%;
  
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  font-weight: 600;
}

.replyPrompt:has(p) {
  display: flex;
  width: 90%;
  z-index: 10;
  left: 5%;
  
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  margin: 0px;
  padding-left: 10px;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  border-radius: 5px;
}
.replyPrompt p {
  margin: 0px;
  padding: 5px;
  
  border-radius: 5px;
  cursor: pointer;
  max-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.replyPrompt p:hover {
  background-color: #1E93FF;
  color: white;
}


.detailParent div {
  flex-wrap: wrap;
}

.cellTransactionCancelled {
  text-decoration: line-through;
}