.navBarRoot {
    display: flex;
    flex:1;
    width: 100vw;
    height: 100vh;

}

.sideBarRoot::-webkit-scrollbar {
  display: none;
}


.sideBarRoot {
    background-color: #111827;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
    width: 280px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.topBar {
    width: 100%;
    height: 65px;
    background-color: white;
    box-shadow: rgb(100 116 139 / 12%) 0px 1px 4px;
    z-index: 1000;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}


.contentMain {
    background-color: #F9FAFC;
    display: flex !important;
    flex-direction: column;
    padding: 24px;
    
    
    /* TODO CHANGE */
    height: 100%;
    overflow: auto;
    
    
}
.contentRoot {
    flex:1;
    height: 100%;
    flex-direction: column-reverse;
    display: flex;
}


.sideBarItem {
    color: #9CA3AF;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    transition: all 250ms;
    margin-top: 5px;
    
    width: 100%;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
}

.sideBarItem.selected {
    color: #10B981;
}

.sideBarItem.selected p, .sideBarItem.selected svg {
    color: #10B981;
    fill: #10B981;
}

.sideBarItem:hover, .sideBarItem.selected {
    background-color: rgba(255, 255, 255, 0.08);
}

.sideBarItemRoot {
    width: calc(100% - 48px);
}

.sideBarItem p {
    color: #9CA3AF;
    font-weight: 600;
    margin-left: 10px;
}

.sideBarItem svg {
    fill: #9CA3AF;
}

.sidemenuBar {
    max-height: 0px;
    transition: all 250ms;
    overflow-y: hidden;
}

.sidemenuBar.extended {
    
    max-height: 100px;
}

.sideBarBackground {
    display: none;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: black;
    pointer-events: none;  
    z-index: 10000;     
}
.navBarMenu {
    display: none;
    padding: 0px;
    align-items: center;
    margin-left: 24px;
    position: absolute;
    left: 0px;
}

@media only screen and (max-width: 700px) {
    .sideBarRoot {
      position: absolute;
      transition: all 250ms;
      transform: translateX(-100%);
        pointer-events: none;
        z-index: 100000;
        
    }

    .sideBarBackground {
        pointer-events: none;
        transition: all 250ms;
        opacity: 0;
        display: block;
    }

    .sideBarBackground.open {
        opacity: .4;
        pointer-events: all;

    }

    .navBarMenu {
        display: flex;
        align-items: center;
    }

    

    .sideBarRoot.open {
        transform: translateX(0);
        pointer-events: all;
    }

    
}