.loginForm {
    box-shadow: rgb(100 116 139 / 12%) 0px 10px 15px;
    max-width: 500px;
    background-color: white;
    padding: 32px;
    border-radius:8px;
    
}




.loginFormInput {
    width: 100%;
}